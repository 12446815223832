.local-cam {
    height: 100%
}

.remote-cam, .remote-cam video {
    width: 100%;
    height: 100%;
}

.btns-visibility {
    visibility: visible !important;
}

#remote-username {
	position: absolute;
	left: 30px;
	bottom: 30px;
	display: -webkit-flex;
	display: flex;
	visibility: hidden;
	z-index: 2;
	color: #FFF;
}

#remote-username #hang-up {
	height: 35px;
	width: 35px;
	margin-right: 10px;
	padding: 5px;
	background-color: #B24556 !important;
	line-height: 36px;
	font-size: 18px;
	background-image: url("../../assets/img/end-call2.png");
	background-position: center;
	background-repeat: no-repeat;
}

.rounded-btn {
	border-radius: 50px;
	align-self: center;
	cursor: pointer;
	cursor: hand;
	background-color: #797979;
}

#video-menu {
	position: absolute;
	display: flex;
	bottom: 30px;
	right: 45%;
	visibility: hidden;
}

#video-menu .rounded-btn {
	width: 45px;
	height: 45px;
}

#video-menu img {
	padding: 10px;
}

#video-menu #chat-btn img {
	padding-left: 12px;
}

#video-menu #remote-full-screen {
	margin-right: 5px;
}

#video-menu #chat-btn {
	background-color: #81b75c;
}

.expand {
	background-image: url('../../assets/img/expand.png');
	background-repeat: no-repeat;
    background-position: center center;
}

.remote-fs-selected {
	background-color: #81b75c;
	background-repeat: no-repeat;
}

.remote-fs-unselected {
	background-color: #797979;
	background-repeat: no-repeat;
}

section.major-box {
	padding: 0;
	background: #2D4899;
	display: -webkit-flex;
	display: flex;
	position: relative;
	height: 100%;
	overflow: hidden;
	width: 100%;
}

section.minor-box {
	height: 150px;
	background: none;

	position: absolute;
	right: 30px;
	bottom: 30px;
}

section.minor-box.box-standby {
	left: 0;
    top: 0;
    height: 135px;
}

section.major-box video {
    flex-grow: 1;
}

section.minor-box video {
    height: 100%;
}


.major-box #co-logo {
	position: absolute;
	right: 18px;
	top: 8px;
	width: 200px;
	height: 100px;
}

section.major-box.hide-vid {
	flex-grow: 0;
}

.img-welcome {
	background-repeat: no-repeat;
    background-position: center;
	background-image: url('../../assets/img/logo-big.png');
    background-size: contain;
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 713px;
    left: 0;
    right: 0;
    margin: auto;
}

#remote-username #camera, #remote-username #microphone {
	margin-left: 2px;
}

.btns-full-screan {
	bottom: 40px !important;
    left: 20px !important;
}

.buttons-action{
    display: block;
    width: 270px;
}

.buttons-action ul{
    padding: 0;
    margin: 0;
}

.buttons-action ul li{
    list-style: none;
    font-size: 20px;
    cursor: pointer;
    color: #ffffff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #81b75c;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 43px;
    margin: 10px 0 0 0;
}

.buttons-action ul li.button-deactivated{
    color: #aba7a7;
    background-color: #797979;
}
