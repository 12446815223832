
.chat-msg {
	background-color: #EBEBEB;
}

.chat-msg.msg-peer {
	background-color: #EBEBEB;
}

.chat-msg.msg-user {
	background-color: #6C6C6C;
	color: #FFF;
}

.chat-msg.msg-info {
	/* border-top-left-radius: 0; */
	/* width: 70%; */
	background-color: #BAECA2;
}

.chat-msg.msg-alert {
	background-color: #F7AEB0;
}

hr {
	border-color: #ABABAB;
	border-width: 0.5px;
}

.chat-linebreak {
	font-size: 11px;
	color: #777;
}

#sendMessage {
	left: 0;
    bottom:0;
	display: -webkit-flex;
	display: flex;
	width: 235px;
	height: 55px;
	margin: 0;
	border-top-color: #B6B6B6;
	border-top-style: solid;
	border-top-width: 1px;
}

#sendMessage #message {
	width: 100%;
	padding: 15px;
	font-size: 13px;
	font-family: inherit;
	border: none;
	outline: none;
}

#sendMessage #submit-btn {
	background-image: url('../../assets/img/chat-on.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-color: #81b75c;
	cursor: pointer;
	cursor: hand;
	border: none;
	width: 65px;
	border-radius: 0;
    background-size: 15px 15px;
}

section.horizontal-bar {
	overflow-y: auto;
	height: 380px;
	position: relative;
	display: -webkit-flex;
	display: flex;
}

#messages {
	padding: 20px;
	width: 100%;
	overflow-y: auto;
    background-color: #fff;
    display: table-cell;
}

.chat-msg.msg-user {
	border-radius: 10px;
	border-top-right-radius: 0;
	margin-left: 28%;
}

.chat-msg {
	padding: 10px;
	border-radius: 10px;
	border-top-left-radius: 0;
	width: 70%;
	margin-bottom: 20px;
}

.chat-msg.msg-info {
	border-top-left-radius: 10px;
	width: 100%;
}

.chat-msg .msg-header {
	font-size: 11px;
	margin-bottom: 10px;
}

.chat-msg .msg-header .msg-time {
	float: right;
}

.chat-content{
    height: auto;
    display: block;
    overflow: hidden;
}

.chat-hidden{
    display: none;
}
