.btn-accept {
    background-color: #98d973;
}
.btn-refuse {
    background-color: #e64942;
}
.btns-calling {
    margin-top: 15px;
    text-align: end; 
}
.btns-calling button {
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    padding: 7px 18px;
    margin: 0 0 0 20px;
    cursor: pointer;
}

.modal-calling p {
    font-weight: bold;
}

.modal-calling {
    max-width: 400px;
}

section.vertical-bar {
	display: unset;
	position: relative;
	width: 270px;
	height: 100%;
	overflow-y: auto;
	padding: 0;
	background-color: #F5F5F5;
	border-right: 1px solid #f8f3f3;
}

@media all and (width: 1366px) {

	section.vertical-bar {
		width: 290px;
		height: 100%;
		overflow-y: auto;
		padding: 0;
		border-right: 1px solid #f8f3f3;
	}
}

#userInfo {
	background-color: #fff;
	display: inline-block;
	width: 100%;
	position: relative;
	padding-bottom: 10px;
}

.hide-btn {
	background-repeat: no-repeat;
	background-position: center center;
	position: absolute;
    border: solid #B6B6B6 1px;
    background-color: #fff;
    background-size: 12px;
	width: 14px;
    height: 30px;
	cursor: pointer;
	cursor: hand;
}

.hide-btn:hover {
	background-color: #dcffc7;
}


#hideColumn {
	background-image: url('../assets/img/arrow_hide.png');
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right-width: 0;
    border-right-color: #fff;
    top: 10px;
	right: 0;
}

#showColumn {
	background-image: url('../assets/img/arrow_show.png');
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left-width: 0;
    border-left-color: #000;
    top: 10px;
	left: 0;
	display: unset;
    z-index: 2;
}

#peers {
	width: 100%;
	overflow: hidden;
}

#peers h2, #peers button {
	float: left;
	margin-right: 10px;
}

.peer {
	clear: left;
	padding: 14px 20px;
	pointer-events: fill;
	overflow: hidden;
	display: flex;
	cursor: pointer;
}

.peer:hover {
	background-color: #EBEBEB;
}

.peer.selected,
.peer.selected:hover {
	background-color: #DFF4D5;
}

.peer-icon {
	margin-right: 10px;
	pointer-events: none;
}

.peer-label {
	pointer-events: none;
}

.sign-out {
	background-image: url('../assets/img/sign-out.png');
	background-repeat: no-repeat;
}

.sign-out:hover {
	background-image: url('../assets/img/sign-out-grn.png');
}

.user-icon-img {
	width: 17px;
	height: 17px;
	background-image: url('../assets/img/user-icon-sm.png');
	background-repeat: no-repeat;
}

.user-icon-img-grn {
	width: 17px;
	height: 17px;
	background-image: url('../assets/img/user-icon-sm-grn.png');
	background-repeat: no-repeat;
}

.start-call {
	background-image: url('../assets/img/start-call.png');
	background-repeat: no-repeat;
}

.files {
    background-image: url('../assets/img/files.png');
    background-repeat: no-repeat;
}

.start-call:hover,
.start-call.active {
	background-image: url('../assets/img/start-call-grn.png');
	background-repeat: no-repeat;
}

.end-call {
	background-image: url('../assets/img/end-call.png');
	background-repeat: no-repeat;
}

.end-call-grn {
	background-image: url('../assets/img/end-call-grn.png');
	background-repeat: no-repeat;
}

.end-call-wt {
	background-image: url('../assets/img/end-call-wt.png');
	background-repeat: no-repeat;
    background-position: 33% 50%;
}

.hide{
    display: none !important;
}

.hide-vartical-bar{
	width: 0 !important;
}

.title-text {
	font-size: 1.7em;
	font-weight: 400;
}

#username-label {
	margin-left: 20px;
	margin-top: 15px;
	margin-bottom: 15px;
	max-width: 175px;
    overflow: hidden;
}

.box {
	width: 100%;
	white-space: nowrap;
	text-align: center;
}

.menu-icon-btns {
	background-repeat: no-repeat;
	background-position: center top;
	text-align: center;
	padding-top: 35px;
	width: 50px;
	height: 15px;
	font-size: 10px;
	cursor: pointer;
	cursor: hand;
	display: inline-block;
	margin: 0 7px;
}

.menu-icon-btns a{
    text-decoration: none;
    color: inherit;
}

#documents li a {
    font-size: 15px;
    color: inherit;
    text-decoration: none;
}

#documents li {
    list-style-type: circle;
    margin: 10px 0 0 0;
    font-weight: bold;
}
