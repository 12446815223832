@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700');

body {
	font-family: 'Open Sans', helvetica, arial;
	font-size: 14px;
	margin: 0;
}

#xsdk-video-call {
	display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
}

.brand-logo {
	background-image: url('../img/brand-logo.png');
	background-repeat: no-repeat;
    background-position: top right;
}

.fb-col-item {
	flex-grow: 1;
}

.hidden {
	display: none;
}

/* modal */

#modal {
	position: relative;
	z-index: 999;
}

.modal {
	display: inline-block !important;
}

.close-modal {
	cursor: pointer;
}

/* /modal */

.full-screan {
	width: 100%;
	height: 100%;
	z-index: 1;
}